@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@200;300;400;500&family=Cairo&family=Poppins&family=Quicksand&display=swap");

* {
  font-family: "Alexandria", sans-serif;
  /* font-weight: 300; */
}
body {
  font-family: "Alexandria", sans-serif;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

::-webkit-scrollbar {
  display: none;
}
@media (max-width: 768px) {
  .card.text-center.glass-card {
    width: 100%;
    max-width: none;
    margin-top: 10px;
  }
  .card-header {
    padding: 0;
  }
  .card-header img {
    width: 100%;
    height: auto;
  }
  .card-body {
    padding: 20px;
  }
  .card-title.title-main {
    font-size: 22px;
  }
  .btn.btn-primary-custom.btn-lg {
    padding: 8px 16px;
    width: fit-content;
    margin: 0;
  }
}

/* Navbar css */

@media (max-width: 991.98px) {
  .responsive-nav-items {
    flex-direction: column;
    align-items: center; /* Center items along the cross-axis (vertically) */
    margin-right: 0;
    margin-left: 2px;
  }

  .title-responsive {
    display: flex;
    justify-content: center;
    margin: 2px auto;
    position: relative;
    margin-left: 5em;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
  }
}

.underline-link {
  position: relative;
}

.underline-link::after {
  content: "";
  position: absolute;
  left: 5px;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 255, 255);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.underline-link:hover::after {
  transform: scaleX(1);
}
/* .nav-item.login {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background: #ffffff;
  color: #ffffff;
  margin-right: 25px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.nav-item.login:hover {
  background-color: #6b3eff;
  color: #ffffff;
} */
.input-group {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.form-group input {
  border-radius: 8px;
}

/* Dropdown Css */
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}
.dropdown-item {
  display: flex;
  align-items: center;
  color: #6b3eff;
  font-size: 16px;
  padding: 8px 16px;
  /* color: #2800ab; */
}
.dropdown-divider {
  border-top: 1px solid #6b3eff;
  margin: 8px 0;
}
.dropdown-item.logout {
  color: #333;
}

.dropdown-item:hover {
  background-color: #9c00da;
  color: white;
}
.dropdown-item.logout:hover {
  background-color: #333;
  color: white;
}

.dropdown-item .fa-icon {
  margin-right: 5px;
}

/* Start Home css */
body {
  margin: 0;
  padding: 0;
  background-image: url("./assets/images/AbstractBG.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  background-size: 100% auto;
  font-family: Arial, sans-serif;
}

.container {
  background-repeat: no-repeat;
  max-width: 900px;
  margin: 0 auto;
}

.card {
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  background-color: #edeaea50;
}

.logo-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.logo-image {
  max-width: 150px;
  max-height: 150px;
}

.card-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-family: "Alexandria", sans-serif;
  font-weight: lighter;
}
.title-main {
  color: rgb(30, 112, 143);
  font-family: "Alexandria", sans-serif;
  font-weight: 400;
}

.title-sub {
  color: #cba709;
  position: relative;
  display: inline-block;
  padding: 0 5px;
  font-family: "Alexandria", sans-serif;
  font-weight: 300;
}

.line-before,
.line-after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20px;
  height: 2px;
  background-color: #000;
}

.line-before {
  background-image: linear-gradient(to right, #80d0c7, #13547a);
  left: -30px;
}

.line-after {
  right: -30px;
  background-image: linear-gradient(to left, #80d0c7, #13547a);
}

.text-right {
  text-align: right;
}

.btn-primary-custom {
  width: 38%;
  background-color: rgb(84, 105, 212);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.btn-primary-custom:hover {
  background-color: rgb(85, 0, 146);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(92, 0, 146, 0.3);
}

/* end home css */

/* start login css */
.card-container {
  max-width: 400px;
  margin: 8 auto;
  padding: 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background-color: #f8f9fa;
}

.profile-img-card {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: block;
}

form {
  text-align: right;
}

label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.custom-input {
  width: 100%;
  padding: 12px;
  border: 2px solid #dcdcdc;
  border-radius: 8px;
  background-color: #f8f8f8;
  transition: border-color 0.3s ease;
}

.custom-input:focus {
  border-color: #459cfa;
}

.custom-input::placeholder {
  color: #9b9b9b;
}
.custom-button {
  width: 100%;
  margin-top: 7%;
  font-size: 18px;
  background-color: rgb(84, 105, 212);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.custom-button:hover {
  background-color: rgb(18, 84, 228);
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 37, 146, 0.3);
}

.custom-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.alert {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: right;
}

.alert-info {
  background-color: #d1ecf1;
  color: #0c5460;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
}
.custom-alert {
  padding: 10px;
  border-radius: 10px;
  background-color: #f8d7da;
  color: #721c24;
  text-align: right;
}

/* end login css */

/* Start Profile css */

.container {
  max-width: 800px;
  margin: 0 auto;
}

.jumbotron {
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 20px;
}

.alert-info {
  background-color: #d1ecf1;
  padding: 20px;
  margin-bottom: 20px;
}

ul {
  padding: 0;
  list-style-type: none;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 5;
  }
}

.jumbotron {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  margin-right: auto;
  position: absolute;
  left: 0;
  width: 19%;
  height: 11%;
  animation: slideInFromLeft 0.5s ease-in-out;
}

/* Responsive adjustments */
/* Profile.css */

/* Alert box css */

.alert-box {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  color: #755800;
  display: block;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  width: 20%;
  animation: slideInFromRight 0.5s ease-in-out;
}

.alert-box h3 {
  margin: 0;
  line-height: 20pt;
  font-size: 18px;
  font-weight: 400;
}

.alert-box p {
  margin: 0;
  margin-left: 10px;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.alert-box .icon {
  float: right;
  margin-right: 10px;
  display: inline;
  color: #ffc107;
  font-size: 20px;
}

/* Lecture Card css */

.card-body {
  text-align: center;
  padding: 20px;
}

.custom-alert {
  color: #5469d4;
  background-color: #f7f7f7;
  border-radius: 4px;
  margin: 10px;
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
}

.custom-text {
  margin-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.video-status {
  margin-top: 3%;
  border: 2px solid green;
  border-radius: 4px;
  color: green;
  padding: 2%;
  font-size: 17px;
  margin-right: 10%;
}

.btn-lg.lec {
  width: 90%;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 8px;
  color: #f90000;
  margin: 0 auto;
}

/* Exam grade css */

.grade {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid;
  border-radius: 4px;
}

.grade-failed {
  color: red;
  border-color: red;
}

.grade-succeeded {
  color: green;
  border-color: green;
}

.grade-pending {
  color: gray;
  border-color: gray;
}

/* Media Queries */

/* For screen sizes up to 576px */
@media (max-width: 576px) {
  .jumbotron {
    width: 100%;
  }

  .alert-box {
    width: 100%;
  }

  .lec {
    width: 100%;
  }
}

/* For screen sizes between 576px and 768px */
@media (min-width: 576px) and (max-width: 768px) {
  .jumbotron {
    width: 40%;
  }

  .alert-box {
    width: 40%;
  }

  .lec {
    width: 40%;
  }
}

/* For screen sizes between 768px and 992px */
@media (min-width: 768px) and (max-width: 992px) {
  .jumbotron {
    width: 30%;
  }

  .alert-box {
    width: 30%;
  }

  .lec {
    width: 30%;
  }
}

/* For screen sizes above 992px */
@media (min-width: 992px) {
  .jumbotron {
    width: 19%;
  }

  .alert-box {
    width: 20%;
  }

  .lec {
    width: 20%;
  }
}

/* end Profile css */

/* Start Session screen css*/

.container-fluid {
  background-color: #fffafb;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.container-fluid {
  padding-top: 3rem;
  width: 100%;
  @media (max-width: 767px) {
    padding-top: 1.7rem;
    width: 100%;
  }
}

.video-container {
  position: relative;
  /* padding-bottom: 56.25%; */
  /* 16:9 aspect ratio */
  /* margin-bottom: 1rem; */
}

.video-frame {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (9 / 16 = 0.5625 or 56.25%) */
  overflow: hidden;
}

.video-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-title {
  color: #000d86;
  font-size: 2rem;
  text-align: center;
}

.video-speaker {
  color: #8a8a00;
  font-size: 1.5rem;
  text-align: center;
}

.video-title {
  color: #000d86;
  font-size: 2rem;
  text-align: center;
  position: relative;
}

.video-speaker {
  color: #8a8a00;
  font-size: 1.5rem;
  text-align: center;
  position: relative;
}

/* side nav css */
.side-navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  padding-top: 2.5rem;
  margin-top: 60px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #dddddd;
  border-bottom-right-radius: 10px;
  transition: width 0.3s ease;

  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 0.2rem;
  }
}

.side-navbar:hover {
  width: 270px; /* Expanded width on hover */
}

.side-navbar ul.navbar-nav {
  list-style: none;
  padding: 0;
  display: block;
  margin-top: 0px;
  margin-left: 35px;
}

.side-navbar .nav-item {
  margin-bottom: 0.5rem;
  margin-top: 0px;
}

.side-navbar .nav-link {
  color: #333;
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.5rem;
  display: block;
}

.side-navbar .nav-link:hover,
.side-navbar .nav-link:focus {
  background-color: #7d43ef;
  color: #fff;
  border-radius: 4px;
}

.side-navbar .nav-link.active {
  background-color: #7d43ef;
  color: #fff;
  border-radius: 4px;
}

.side-navbar .nav-link.active:hover,
.side-navbar .nav-link.active:focus {
  background-color: #7d43ef;
  color: #fff;
}

@media (max-width: 991.98px) {
  .side-navbar {
    margin-bottom: 1rem;
    margin-top: 0px;
    padding-top: 0px;
    position: relative;
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom-right-radius: 0;
    box-shadow: none;
    transition: none;
    display: flex;
    flex-direction: row-reverse; /* Align items to the right */
    justify-content: flex-end; /* Align items horizontally to the right */
  }

  .side-navbar .nav-item {
    /* padding-top: 0.25rem; */
    padding-bottom: 0.25rem;
    float: right;
    margin-right: 30px;
  }

  .side-navbar:hover {
    width: 100%;
  }
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

/* Alert css */

/* end Session screen css*/

/* Start Quiz Screen */
.quiz-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
}

.quiz-card-number-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  margin-right: 3%;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.416);
}

.quiz-card-number {
  padding: 5px;
  background-color: #e0dfdf;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.quiz-card-number:hover {
  background-color: #007bff;
  color: #ffffff;
}

.quiz-card-number.active {
  background-color: #007bff;
  color: #ffffff;
}

.quiz-card {
  max-width: 500px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.quiz-card-question {
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 1.5;
  color: #333333;
}

.quiz-card-answer {
  margin-top: 20px;
}

.quiz-card-select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}

.quiz-card-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.quiz-card-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.btn-next {
  background-color: #007bff;
  color: #ffffff;
}

.btn-previous {
  background-color: #6c757d;
  color: #ffffff;
}

.btn-submit {
  background-color: rgb(191, 6, 0);
  color: #ffffff;
}
.btn-next {
  background-color: #007bff;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.btn-previous {
  background-color: #6c757d;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.btn-submit {
  background-color: rgb(191, 6, 0);
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.btn-next:hover {
  background-color: #0056b3;
}
.btn-previous:hover {
  background-color: #42474c;
}
.btn-submit:hover {
  background-color: rgb(171, 7, 1);
}

.btn-submit .spinner-border {
  margin-right: 5px;
}

.quiz-card-number.unanswered {
  background-color: rgb(252, 98, 98);
  color: white;
}

.alert {
  margin-top: 10px;
}

/* Media Queries */
@media (max-width: 768px) {
  .quiz-card-container {
    flex-direction: column;
    align-items: stretch;
  }

  .quiz-card-number-list {
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 90%;
  }

  .quiz-card {
    max-width: 100%;
  }
}

/* End Quiz Screen */
/* Start Register css */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  margin-top: 2%;
  margin-bottom: 2%;
}

.register-form {
  width: 90%;
  max-width: 500px;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-group {
  /* margin-bottom: 20px; */
}

label {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

input[type="text"],
input[type="date"],
select {
  width: 100%;
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  outline: none;
  /* border-bottom: 1px solid #8b8a8a; */
  border-radius: 4px;
}

select {
  height: 50px;
}

.btn-register {
  display: block;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  margin: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #443dd3;
  color: #fff;
  position: relative;
}
.btn-register:hover {
  background-color: #00568f;
}

.btn-register:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.next-button,
.previous-button,
.submit-button {
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  margin: 10px 0;
}

.next-button {
  background-color: green;
  color: white;
}

.previous-button {
  background-color: purple;
  color: white;
}

.next-button:hover,
.previous-button:hover {
  background-color: #03984e;
}

.previous-button:hover {
  background-color: #8e44ad;
}

.spinner-border {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 2px solid #007bff;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}
.error-message {
  color: rgb(116, 0, 0);
  background-color: rgb(255, 194, 194);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.file-upload {
  display: inline-block;
  border: 1px solid #ccc;
  background-color: #f3f0f0d1;
  border-color: #272626;
  margin-left: 10%;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
  width: fit-content;
}

.file-upload:hover {
  border-color: #000000;
  background-color: #e8e6e6cc;
}

.file-input {
  display: none;
}

.file-label {
  cursor: pointer;
  margin-left: 5px;
}

.file-text {
  text-decoration: none;
  color: #333;
  margin: 10px;
  cursor: pointer;
}

.file-placeholder {
  color: #0f0f0f;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

/* Loading spinner animation keyframes */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alert {
  padding: 10px;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.text-right {
  text-align: right;
}

li {
  list-style: none;
}

input[type="checkbox"] {
  margin-right: 5px;
}

/* page numbering css */
.page-indicator-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 20px;
  color: #333;
}

.page-indicator {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.page-number,
.of-text,
.total-pages {
  margin: 0 5px;
}

/* Container for the edit buttons */
.edit-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Common styles for the edit buttons */
.edit-button {
  color: white;
  border: none;
  padding: 10px;
  margin: 15px 5px 5px;
  cursor: pointer;
  border-radius: 8px;
  width: 50%;
  transition: background-color 0.3s ease-in-out;
}

/* Styling for the first edit button */
.first-edit {
  background-color: #059d3f;
}

.first-edit:hover {
  background-color: #005b14;
}

/* Styling for the second edit button */
.second-edit {
  background-color: #8e44ad;
}

.second-edit:hover {
  background-color: #4d026d;
}

/* Styling for the registration button (btn-register) */

/* End Register css */

/* Start Payment css */

/* Payment.css */

/* Styles for the container div */
.payment-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.card-container-lg {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  direction: rtl;
}

.card-container-lg p {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
  direction: rtl;
}

.card-container-lg ul {
  margin-bottom: 10px;
  color: #555;
  direction: rtl;
  line-height: 1.6;
}

.card-container-lg li {
  margin-bottom: 10px;
  padding-right: 15px;
  position: relative;
  line-height: 1.6;
}

.card-container-lg li:before {
  content: "•";
  position: absolute;
  right: 0;
  color: #555;
}

.line-wrapper {
  display: flex;
  justify-content: space-between;
}

.line {
  flex-grow: 1;
  background: linear-gradient(to right, #80d0c7, #13547a);
  height: 1.6px;
  margin-top: 2.5%;
  margin-left: 2%;
  margin-right: 2%;
}

/* End Payment css */
